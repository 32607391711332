<template>
  <v-row>
    <v-col class="d-flex align-center">
      <v-btn icon>
        <v-icon @click="previousDate"> $chevron-left</v-icon>
      </v-btn>

      <change-calendar-date />

      <v-btn icon>
        <v-icon @click="nextDate"> $chevron-right</v-icon>
      </v-btn>

      <v-btn
        class="mx-2"
        :outlined="isTodaySelected"
        :disabled="isTodaySelected"
        :text="!isTodaySelected"
        :small="$vuetify.breakpoint.mdAndDown"
        @click="selectToday"
        v-text="config.label"
      />

      <v-spacer />

      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          v-for="(button, index) in buttons"
          :key="index"
          :class="{ 'mr-2': index < buttons.length - 1 }"
          :outlined="currentView === button"
          :color="currentView === button ? 'blue' : ''"
          :text="currentView !== button"
          :small="$vuetify.breakpoint.mdAndDown"
          @click="select(button)"
          v-text="$trans(button)"
        />
      </template>
      <template v-else>
        <v-menu offset-y :close-on-click="true">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>$menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(button, i) in buttons"
              :key="i"
              @click="select(button)"
            >
              <v-list-item-title :class="button.class ? button.class : ''">
                {{ $trans(button) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $router.push({ name: 'sales', params: { tab: 'events' } })
              "
            >
              <v-list-item-title>
                {{ $trans("user_bookings") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$root.$emit('reloadCalendar')">
              <v-list-item-title>
                {{ $trans("refresh") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="hasPermission(employeePermissions.EDIT_SETTINGS)"
              @click="showCalendarSettings"
            >
              <v-list-item-title>
                {{ $trans("calendar_settings") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-spacer />
        <v-btn
          class="mr-2"
          text
          :small="$vuetify.breakpoint.mdAndDown"
          @click="$router.push({ name: 'sales', params: { tab: 'events' } })"
          v-text="$trans('user_bookings')"
        />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              icon
              :loading="isLoading"
              v-bind="attrs"
              v-on="on"
              @click="$root.$emit('reloadCalendar')"
            >
              <v-icon> $reset</v-icon>
            </v-btn>
          </template>
          {{ $trans("refresh") }}
        </v-tooltip>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import moment from "@/lib/calendesk-js-library/plugins/moment";
import { mapGetters, mapMutations } from "vuex";
import ChangeCalendarDate from "@/views/dashboard/pages/Calendar/components/ChangeCalendarDate";
import helpers, { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "CalendarToolbar",
  components: { ChangeCalendarDate },
  mixins: [roleActions],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lgScreenViews: [
        // "listWeek", // TMP off
        "resourceTimeGridDay",
        "timeGridWeek",
        "dayGridMonth",
      ],
      options: {
        listWeek: {
          label: this.$trans("today"),
          type: "days",
        },
        resourceTimeGridDay: {
          label: this.$trans("today"),
          type: "days",
        },
        timeGridWeek: {
          label: this.$trans("today"),
          type: "weeks",
        },
        dayGridMonth: {
          label: this.$trans("today"),
          type: "months",
        },
      },
      date: new Date().toISOString().substr(0, 10),
    };
  },
  computed: {
    ...mapGetters({
      selectedDate: "calendar/getSelectedDate",
      currentView: "calendar/getCurrentView",
    }),
    isTodaySelected() {
      return this.selectedDate === new Date().toISOString().substr(0, 10);
    },
    buttons() {
      return this.lgScreenViews;
    },
    config() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.options.hasOwnProperty(this.currentView)) {
        return this.options[this.currentView];
      }
      return this.options.resourceTimeGridDay;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedDate: "calendar/SET_SELECTED_DATE",
    }),
    select(view) {
      if (
        this.$parent &&
        this.$parent.$refs &&
        this.$parent.$refs.fullCalendar &&
        this.$parent.$refs.fullCalendar.getApi()
      ) {
        this.$parent.$refs.fullCalendar.getApi().changeView(view);
        localStorage.calendarCurrentView = view;

        pushEvent("updateCalendarView", {
          view,
        });
      }
    },
    selectToday() {
      this.setSelectedDate(new Date().toISOString().substring(0, 10));
    },
    previousDate() {
      const previousDate = moment(this.selectedDate)
        .subtract(1, this.config.type)
        .format(helpers.dateFormat);

      this.setSelectedDate(previousDate);
    },
    nextDate() {
      const previousDate = moment(this.selectedDate)
        .add(1, this.config.type)
        .format(helpers.dateFormat);

      this.setSelectedDate(previousDate);
    },
    showCalendarSettings() {
      this.openDialog({
        type: dialogTypes.CALENDAR_SETTINGS,
        size: dialogSize.LARGE,
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
        title: this.$trans("calendar_settings"),
      });
    },
  },
};
</script>
