import { config } from "@/lib/calendesk-js-library/prototypes/config";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import helpers from "@/lib/calendesk-js-library/tools/helpers";
import moment from "@/lib/calendesk-js-library/plugins/moment";

import store from "@/store";
import employeeColors from "@/calendesk/tools/employeeColors";
import tippy from "tippy.js";
import {
  displayCalendarDate,
  getCalendarEventTileDescription,
} from "@/calendesk/tools/calendarHelpers";

export function handleViewAfterMount(info) {
  if (Object.prototype.hasOwnProperty.call(resolver, info.view.type)) {
    resolver[info.view.type](info);
  }
}

const resolver = {
  listWeek: handleListWeekView,
  resourceTimeGridDay: handleResourceTimeGridDayView,
  timeGridWeek: handleTimeGridWeekView,
  dayGridMonth: handleDayGridMonthView,
};

function isLargeCalendarScreen(info) {
  return info && info.view && info.view.calendar && info.view.calendar.el
    ? info.view.calendar.el.offsetWidth > 960
    : false;
}

export function handleListWeekView(info) {
  const isBlockingSpot =
    info.event.extendedProps.type === helpers.eventTypes.unavailableBookingSlot;
  let { employee } = info.event.extendedProps;
  let showAvatar = !!(employee && employee.user && employee.user.default_image);

  if (!showAvatar && !employee && info.event.extendedProps.employee_id) {
    const employees = store.getters["employee/getAllEmployees"];

    if (employees) {
      const found = employees.find(
        (employee) => employee.id === info.event.extendedProps.employee_id
      );
      if (found && !!(found && found.user && found.user.default_image)) {
        employee = found;
        showAvatar = true;
      }
    }
  }

  const color = isBlockingSpot ? "primary" : "green";

  const elEventGraph = info.el.getElementsByClassName(
    "fc-list-event-graphic"
  )[0];
  const elEventDot =
    elEventGraph.getElementsByClassName("fc-list-event-dot")[0];
  const elChip = elEventGraph.getElementsByClassName("list-view-chip")[0];
  const elAvatar = elEventGraph.getElementsByClassName("list-view-avatar")[0];

  if (elEventDot) {
    elEventDot.remove();
  }

  if (elChip) {
    elChip.remove();
  }

  if (elAvatar) {
    elAvatar.remove();
  }

  if (isLargeCalendarScreen(info)) {
    const chip = document.createElement("span");
    const chipText = document.createElement("span");
    chip.className = `v-chip v-chip--outlined theme--light v-size--default ${color} ${color}--text list-view-chip`;
    chipText.className = "v-chip__content text-uppercase";
    chipText.textContent = `${trans(isBlockingSpot ? "block" : "visit")}`;
    chip.appendChild(chipText);

    elEventGraph.appendChild(chip);

    if (
      showAvatar &&
      employee &&
      employee.user &&
      employee.user.default_image &&
      employee.user.default_image.name
    ) {
      const avatar = document.createElement("div");
      avatar.className = "v-avatar mr-2 v-avatar--left list-view-avatar";
      const avatarResponsiveImage = document.createElement("div");
      avatarResponsiveImage.className = "v-responsive v-image";

      const avatarResponsiveImageSizer = document.createElement("div");
      avatarResponsiveImageSizer.className =
        "v-responsive__sizer list-view-avatar-responsive-image-sizer";
      avatarResponsiveImage.appendChild(avatarResponsiveImageSizer);

      const avatarResponsiveImageCover = document.createElement("div");
      avatarResponsiveImageCover.className =
        "v-image__image v-image__image--cover list-view-avatar-responsive-image-cover";
      const url = config("image_base_url") + employee.user.default_image.name;
      avatarResponsiveImageCover.style.backgroundImage = `url("${url}")`;
      avatarResponsiveImage.appendChild(avatarResponsiveImageCover);

      avatar.appendChild(avatarResponsiveImage);

      elEventGraph.appendChild(avatar);
    }
  }
}

export function handleResourceTimeGridDayView(info) {
  renderTile(info);
  renderTooltip(info);
}

export function handleTimeGridWeekView(info) {
  renderTile(info);
  renderTooltip(info);
}

export function handleDayGridMonthView(info) {
  renderTooltip(info);
}

function renderTooltip(info) {
  if (
    !info.isDragging &&
    !info.isResizing &&
    info.event.extendedProps &&
    info.event.extendedProps.type &&
    isLargeCalendarScreen(info)
  ) {
    const startDate = moment(info.event.startStr).format(helpers.dateFormat);
    const endDate = moment(info.event.endStr).format(helpers.dateFormat);

    let dateInfo = displayCalendarDate({
      start_date: startDate,
      end_date: endDate,
      start_time: info.event.extendedProps.start_time,
      end_time: info.event.extendedProps.end_time,
      starts_at: info.event.extendedProps.starts_at,
      ends_at: info.event.extendedProps.ends_at,
    });

    let fullDescription = `<b>${dateInfo} </b>`;

    const isBlockingSpot =
      info.event.extendedProps.type ===
      helpers.eventTypes.unavailableBookingSlot;
    const isBooking =
      info.event.extendedProps.type === helpers.eventTypes.booking;
    const isGroupBooking =
      info.event.extendedProps.type === helpers.eventTypes.groupBooking;
    const paid = info.event.extendedProps.paid;
    const tags = info.event.extendedProps.tags;
    const products = info.event.extendedProps.products;
    const groupId = info.event.extendedProps.group_id;
    const description = info.event.extendedProps.description;
    const status = info.event.extendedProps.status;
    const id = info.event.id;
    const bookings = info.event.extendedProps.bookings;
    const divider =
      '<hr role="separator" aria-orientation="horizontal" class="v-divider theme--dark" style="margin: 12px 0">';

    if (info.event.extendedProps.tooltip_title) {
      fullDescription += `<br>${info.event.extendedProps.tooltip_title}`;
    }

    if (isGroupBooking && bookings) {
      fullDescription += divider;

      fullDescription += `<div style="margin: 12px 0"><b>${trans(
        "bookings_in_group"
      )}:</b></div>`;

      let len = bookings.length;
      const maxBookings = 3;

      for (let i = 0; i < Math.min(maxBookings, len); i++) {
        let booking = bookings[i];
        fullDescription += '<div style="margin: 12px 0">';
        fullDescription += `${trans("id")}: ${
          booking.id
        }, ${getCalendarEventTileDescription(
          booking,
          helpers.eventTypes.booking
        )}`;

        fullDescription += "<br><b>[" + trans(booking.status) + "]</b> ";
        fullDescription += booking.paid ? "<b>[" + trans("paid") + "]</b>" : "";
        fullDescription += "</div>";
      }

      if (len > maxBookings) {
        fullDescription += `<div class="mt-2">(+${len - maxBookings} ${trans(
          "more"
        )})</div>`;
      }
    }

    let tagsText = divider;

    if ((isBooking || isBlockingSpot) && id) {
      tagsText += `<b>[${trans("id")}: ${id}]</b> `;
    }

    if (isBooking && status) {
      tagsText += "<b>[" + trans(status) + "]</b> ";
    }

    if (isBooking && paid) {
      tagsText += "<b>[" + trans("paid") + "]</b> ";
    }

    if ((isBooking || isBlockingSpot) && groupId) {
      tagsText += "<b>[" + trans("recurrence_event") + "]</b> ";
    }

    if (isBooking && tags && tags.length > 0) {
      for (const index in tags) {
        tagsText += "<b>[" + tags[index].name + "]</b> ";
      }
    }

    if (isBooking && products && products.length > 0) {
      for (const index in products) {
        tagsText += "<b>[" + products[index].name + "]</b> ";
      }
    }

    if (tagsText !== divider) {
      fullDescription += tagsText;
    }

    if (isBooking && description) {
      fullDescription +=
        divider + `<b>${trans("private_notes")}</b>: ` + description;
    } else if (isBlockingSpot) {
      fullDescription +=
        divider +
        `<b>${trans("private_notes")}</b>: ` +
        (description || trans("unavailable_spot"));
    }

    if (info.event && info.event.display === "background") {
      fullDescription = `<b>${dateInfo} </b><br>${trans(
        "employee_working_hours"
      )}`;
    }

    tippy(info.el, {
      content: fullDescription,
      animation: "scale-subtle",
      allowHTML: true,
      arrow: false,
      hideOnClick: true,
    });
  }
}

function renderTile(info) {
  const isBlockingSpot =
    info.event.extendedProps.type === helpers.eventTypes.unavailableBookingSlot;

  const eventContent = info.el.getElementsByClassName("fc-event-title");
  const time = info.el.getElementsByClassName("fc-event-time");
  let timeEl = null;

  let backgroundColor = info.event.backgroundColor;
  let textColor = isBlockingSpot
    ? employeeColors.defaultUnavailableBookingSpotTextColor
    : employeeColors.getTextColorForBackgroundColor(backgroundColor);

  if (!isBlockingSpot && info.event.extendedProps.status === "cancelled") {
    backgroundColor = employeeColors.defaultCanceledBookingBackgroundColor;
    textColor = employeeColors.defaultCanceledBookingTextColor;
  }

  if (time && time.length > 0) {
    timeEl = time[0];
    timeEl.style.color = textColor;
  }

  if (eventContent && eventContent.length > 0) {
    const tags = info.event.extendedProps.tags;
    const products = info.event.extendedProps.products;
    const paid = info.event.extendedProps.paid;
    const groupId = info.event.extendedProps.group_id;

    if (paid && timeEl) {
      const paidChip = document.createElement("div");
      const paidChipText = document.createElement("div");
      paidChip.className = `v-chip theme--light v-size--x-small ml-2`;
      paidChip.style.backgroundColor = textColor;
      paidChip.style.color = backgroundColor;
      paidChipText.className = "v-chip__content text-uppercase chip-text mb-1";
      paidChipText.textContent = trans("paid");
      paidChip.appendChild(paidChipText);

      timeEl.appendChild(paidChip);
      timeEl.className = "d-flex align-center fc-event-time";
    }

    if (groupId && timeEl) {
      const recurringChip = document.createElement("div");
      const recurringChipText = document.createElement("div");
      recurringChip.className = `v-chip theme--light v-size--x-small ml-2`;
      recurringChip.style.backgroundColor = textColor;
      recurringChip.style.color = backgroundColor;
      recurringChipText.className =
        "v-chip__content text-uppercase chip-text mb-1";
      recurringChipText.textContent = trans("recurrence_event");
      recurringChip.appendChild(recurringChipText);

      timeEl.appendChild(recurringChip);
      timeEl.className = "d-flex align-center fc-event-time";
    }

    // Add tags
    if (tags && tags.length > 0) {
      const content = eventContent[0];

      content.appendChild(document.createElement("br"));

      for (const index in tags) {
        const chip = document.createElement("div");
        const chipText = document.createElement("div");
        chip.className = `v-chip theme--light v-size--x-small mr-1`;
        chip.style.backgroundColor = textColor;
        chip.style.color = backgroundColor;
        chipText.className = "v-chip__content text-uppercase chip-text mb-1";
        chipText.textContent = tags[index].name;
        chip.appendChild(chipText);
        content.appendChild(chip);
      }
    }

    // Add products
    if (products && products.length > 0) {
      const content = eventContent[0];

      content.appendChild(document.createElement("br"));

      for (const index in products) {
        const chip = document.createElement("div");
        const chipText = document.createElement("div");
        chip.className = `v-chip theme--light v-size--x-small mr-1`;
        chip.style.backgroundColor = textColor;
        chip.style.color = backgroundColor;
        chipText.className = "v-chip__content text-uppercase chip-text mb-1";
        chipText.textContent = products[index].name;
        chip.appendChild(chipText);
        content.appendChild(chip);
      }
    }
  }
}
