<template>
  <calendar-toolbar-datepicker
    v-model="date"
    :label="$trans('select_date')"
    @input="onDateChange"
  />
</template>

<script>
import { mapMutations } from "vuex";
import CalendarToolbarDatepicker from "@/views/dashboard/pages/Offer/Categories/CalendarToolbarDatepicker.vue";

export default {
  name: "ChangeCalendarDate",
  components: { CalendarToolbarDatepicker },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    datepickerMenu: false,
  }),
  mounted() {
    this.setSelectedDate(this.date);
  },
  methods: {
    ...mapMutations({
      setSelectedDate: "calendar/SET_SELECTED_DATE",
    }),
    onDateChange() {
      this.datepickerMenu = false;
      this.setSelectedDate(this.date);
    },
  },
};
</script>
