<template>
  <div>
    <calendar-toolbar
      v-if="!employeeNotSelected"
      class="mb-4"
      :is-loading="isLoadingEvents"
    />
    <employee-not-selected-overlay v-if="employeeNotSelected" />
    <full-calendar
      v-if="!employeeNotSelected"
      ref="fullCalendar"
      class="calendesk-calendar"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import plLocale from "@fullcalendar/core/locales/pl";
import enLocale from "@fullcalendar/core/locales/en-gb";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import listPlugin from "@fullcalendar/list";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { debounce } from "debounce";
import adaptivePlugin from "@fullcalendar/adaptive";
import calendarHelpers, {
  getResourceFreeSlots,
  getEvents,
} from "@/calendesk/tools/calendarHelpers";
import CalendarToolbar from "@/views/dashboard/pages/Calendar/components/CalendarToolbar";
import { handleViewAfterMount } from "@/views/dashboard/pages/Calendar/components/CalendeskCalendarOverwrites";
import { EventData } from "@/views/dashboard/pages/Calendar/components/EventData";
import helpers, {
  arraysEqual,
  getEmployeeForId,
  pushEvent,
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import EmployeeNotSelectedOverlay from "@/views/dashboard/pages/Calendar/components/EmployeeNotSelectedOverlay";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "CalendeskFullCalendar",
  components: {
    EmployeeNotSelectedOverlay,
    CalendarToolbar,
    FullCalendar,
  },
  mixins: [roleActions],
  data() {
    return {
      isLoadingEvents: false,
      currentEventData: new EventData(),
      calendarOptions: {
        timeZone: userTimeZone(),
        displayEventEnd: true,
        resourceOrder: "-sort_id",
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resources: [],
        eventMaxStack: 2,
        dayMaxEvents: 10,
        unselectAuto: false,
        selectable: true,
        selectMirror: true,
        editable: true,
        contentHeight: 1000,
        nowIndicator: true,
        weekNumbers: true,
        navLinks: true,
        eventSources: [
          {
            events: this.getEvents,
          },
          {
            events: this.getResourceFreeSlots,
          },
        ],
        headerToolbar: {
          left: "prev,next today",
          right: "listWeek, resourceTimeGridDay, timeGridWeek, dayGridMonth",
        },
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        locales: [plLocale, enLocale],
        locale: this.$config("language") === "pl" ? "pl" : "en-gb",
        initialView:
          localStorage.calendarCurrentView ??
          (this.$vuetify.breakpoint.mdAndUp
            ? "timeGridWeek"
            : "resourceTimeGridDay"),
        businessHours: calendarHelpers.businessHours(),
        listDaySideFormat: false,
        listDayFormat: {
          month: "long",
          year: "numeric",
          day: "numeric",
          weekday: "long",
        },
        slotMinTime: this.$config("calendar_time").from,
        slotMaxTime: this.$config("calendar_time").to,
        slotDuration: this.$config("calendar_slots_duration"),
        slotLabelInterval: this.$config("calendar_slots_label_interval"),
        expandRows: true,
        weekNumberFormat: {
          week: "numeric",
        },
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          resourceTimeGridPlugin,
          adaptivePlugin,
        ],
        eventDidMount: this.handleEventDidMount,
        viewDidMount: this.handleViewDidMount,
        eventClick: this.handleEventClick,
        select: this.handleDateSelect,
        eventDrop: this.handleEventMove,
        eventResize: this.handleEventMove,
        loading: this.handleLoading,
        resourceLabelContent: this.handleResourceLabelContent,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedDate: "calendar/getSelectedDate",
      currentView: "calendar/getCurrentView",
      getSelectedEmployees: "calendar/getSelectedEmployees",
      employees: "employee/getActiveEmployees",
      shouldRefreshBookings: "booking/shouldRefreshBookings",
      getBookingCalendarState: "booking/getBookingCalendarState",
    }),
    employeeNotSelected() {
      return (
        this.calendarOptions &&
        this.calendarOptions.resources &&
        this.calendarOptions.resources.length < 1
      );
    },
  },
  watch: {
    getBookingCalendarState() {
      this.unselectCalendar();
    },
    shouldRefreshBookings(reloadCalendar) {
      this.currentEventData = new EventData();

      if (reloadCalendar === true) {
        this.reloadCalendar();
      } else {
        this.unselectCalendar();
      }
    },
    getSelectedEmployees() {
      this.assignEmployeesToCalendar();
    },
    "calendarOptions.resources": function (newValue, oldValue) {
      const arr1 =
        newValue.length > 0 ? newValue.map((employee) => employee.id) : [];
      const arr2 =
        oldValue.length > 0 ? oldValue.map((employee) => employee.id) : [];

      const reloadCalendar = !arraysEqual(arr1, arr2);

      if (reloadCalendar) {
        this.reloadCalendar();
      }
    },
    selectedDate(newValue) {
      const calendar = this.$refs.fullCalendar.getApi();

      if (calendar) {
        const calendarCurrentDate = calendar.currentData
          ? calendar.currentData.currentDate
          : null;

        if (calendarCurrentDate) {
          const currentDate = this.$moment(calendarCurrentDate).startOf("day");

          const newDate = this.$moment(newValue).startOf("day");
          if (!currentDate.isSame(newDate)) {
            calendar.gotoDate(newValue);
          }
        }
      }
    },
  },
  created() {
    this.assignEmployeesToCalendar();
  },
  mounted() {
    this.$root.$on("reloadCalendarSettings", this.reloadCalendarSettings);
    this.$root.$on("reloadCalendar", this.reloadCalendar);
  },
  beforeDestroy() {
    this.$root.$off("reloadCalendarSettings", this.reloadCalendarSettings);
    this.$root.$off("reloadCalendar", this.reloadCalendar);
  },
  methods: {
    ...mapActions({
      updateBooking: "booking/updateBooking",
      updateUnavailableBookingSpot: "booking/updateUnavailableBookingSpot",
    }),
    ...mapMutations({
      setCurrentView: "calendar/SET_CURRENT_VIEW",
      setSelectedEmployees: "calendar/SET_SELECTED_EMPLOYEES",
      setSelectedDate: "calendar/SET_SELECTED_DATE",
    }),
    cleanTooltips() {
      const tooltips = document.querySelectorAll("[data-tippy-root]");
      tooltips.forEach((tooltip) => {
        tooltip.remove();
      });
    },
    assignEmployeesToCalendar() {
      if (this.loggedUserCanSeeAllEmployees) {
        const newResources = [];
        const removeInvalidEmployeeIDs = [];

        if (this.getSelectedEmployees && this.getSelectedEmployees.length > 0) {
          this.getSelectedEmployees.forEach((employeeId) => {
            const employee = this.employees.find(
              (resource) => resource.id === parseInt(employeeId)
            );
            if (employee) {
              const resource = this.$helpers.cloneObject(employee);
              resource.sort_id = employee.id;
              newResources.push(resource);
            } else {
              removeInvalidEmployeeIDs.push(employeeId);
            }
          });
        }

        const arr1 =
          this.calendarOptions.resources.length > 0
            ? this.calendarOptions.resources.map((employee) => employee.id)
            : [];
        const arr2 =
          newResources.length > 0
            ? newResources.map((employee) => employee.id)
            : [];

        const updateResources = !arraysEqual(arr1, arr2);

        if (removeInvalidEmployeeIDs.length > 0) {
          this.setSelectedEmployees(arr2);
        }

        if (updateResources) {
          this.calendarOptions.resources = newResources;
        }
      } else if (this.loggedUserEmployee) {
        this.calendarOptions.resources = [this.loggedUserEmployee];
      }
    },
    reloadCalendarSettings() {
      this.calendarOptions.businessHours = calendarHelpers.businessHours();

      this.calendarOptions.slotMinTime = this.$config("calendar_time").from;
      this.calendarOptions.slotMaxTime = this.$config("calendar_time").to;
      this.calendarOptions.slotDuration = this.$config(
        "calendar_slots_duration"
      );
      this.calendarOptions.slotLabelInterval = this.$config(
        "calendar_slots_label_interval"
      );

      this.reloadCalendar();
    },
    reloadCalendar() {
      if (this.$refs.fullCalendar) {
        const calendar = this.$refs.fullCalendar.getApi();

        if (calendar) {
          calendar.setOption("editable", false);

          const dayMaxEvents = this.$vuetify.breakpoint.smAndDown ? 0 : 10;
          calendar.setOption("dayMaxEvents", dayMaxEvents);
          calendar.refetchEvents();
          calendar.unselect();
        }
      }

      this.cleanTooltips();
    },
    unselectCalendar() {
      if (this.$refs.fullCalendar) {
        const calendar = this.$refs.fullCalendar.getApi();

        if (calendar) {
          calendar.unselect();
        }
      }
    },
    handleLoading(loading) {
      this.isLoadingEvents = loading;

      const calendar = this.$refs.fullCalendar.getApi();

      if (calendar && !loading) {
        calendar.setOption("editable", true);
      }
    },
    handleResourceLabelContent({ resource }) {
      if (
        this.calendarOptions.resources.length > 10 &&
        resource.extendedProps.user.name &&
        resource.extendedProps.user.surname
      ) {
        return (
          resource.extendedProps.user.name.charAt(0).toUpperCase() +
          resource.extendedProps.user.surname.charAt(0).toUpperCase()
        );
      }

      return `${resource.extendedProps.user.name} ${resource.extendedProps.user.surname}`;
    },
    handleEventDidMount(info) {
      // This will remove background events if there is a date that overrides week days
      if (
        info.event &&
        info.event.display === "background" &&
        info.event.extendedProps &&
        info.event.extendedProps.schedule &&
        info.event.extendedProps.scheduleRuleType ===
          this.$helpers.scheduleRuleType.WDAY
      ) {
        info.event.extendedProps.schedule.rules.forEach((rule) => {
          const date = this.$moment(info.event.start).format(
            this.$helpers.dateFormat
          );
          if (
            rule.type === this.$helpers.scheduleRuleType.DATE &&
            rule.date === date
          ) {
            info.event.remove();
          }
        });
      }

      this.cleanTooltips();
      handleViewAfterMount(info);
    },
    handleViewDidMount(info) {
      this.setCurrentView(info.view.type);
      if (info.view.type === "dayGridMonth") {
        this.calendarOptions.weekNumberFormat = {
          week: "numeric",
        };
      } else {
        this.calendarOptions.weekNumberFormat = {
          week: "narrow",
        };
      }

      if (info.view.type === "resourceTimeGridDay") {
        const currentData = info.view.getCurrentData();

        if (currentData && currentData.currentDate) {
          this.setSelectedDate(
            currentData.currentDate.toISOString().substring(0, 10)
          );
        }
      }

      this.cleanTooltips();
    },
    fillEventWithDates(start, end, allDay) {
      this.currentEventData.allDay = allDay;

      const startMoment = moment(start);

      let endMoment = moment(end || start);

      this.currentEventData.startDate = startMoment.format(helpers.dateFormat);
      this.currentEventData.startTime = startMoment.format("HH:mm");

      if (allDay && end) {
        endMoment.subtract(1, "d");
      }

      this.currentEventData.endDate = endMoment.format(helpers.dateFormat);
      this.currentEventData.endTime = endMoment.format("HH:mm");

      // Add UTC start and end times
      this.currentEventData.startsAt = startMoment.utc().toISOString();
      this.currentEventData.endsAt = endMoment.utc().toISOString();
    },
    handleEventClick(info) {
      this.cleanTooltips();

      if (info.event && info.event.id) {
        this.currentEventData = new EventData();
        this.currentEventData.id = info.event.id;
        this.currentEventData.type = info.event.extendedProps.type;

        if (
          info.event.extendedProps.type ===
          this.$helpers.eventTypes.groupBooking
        ) {
          this.currentEventData.multiSlotGroupId = info.event.id;
        }

        this.$root.$emit("openEventPreviewModal", this.currentEventData);
      }
    },
    handleDateSelect(info) {
      this.currentEventData = new EventData();

      if (info.resource && info.resource.id) {
        this.currentEventData.employee = getEmployeeForId(info.resource.id);
      } else if (
        this.getSelectedEmployees &&
        this.getSelectedEmployees.length === 1
      ) {
        this.currentEventData.employee = getEmployeeForId(
          this.getSelectedEmployees[0]
        );
      }

      this.currentEventData.type = helpers.eventTypes.booking;

      this.fillEventWithDates(info.startStr, info.endStr, info.allDay);

      this.$root.$emit("openEventModal", this.currentEventData);
    },
    handleEventMove(info) {
      this.cleanTooltips();

      this.currentEventData = new EventData();
      this.currentEventData.id = info.event.id;
      this.currentEventData.type = info.event.extendedProps.type;
      this.currentEventData.description = info.event.extendedProps.description;

      this.currentEventData.updateWithoutAllData = true;

      if (info.newResource) {
        if (
          this.currentEventData.type ===
          helpers.eventTypes.unavailableBookingSlot
        ) {
          errorNotification(
            "blackout_date_move_to_another_resource_error",
            {},
            false
          );
          info.revert();
          return;
        }

        this.currentEventData.employee = { id: parseInt(info.newResource.id) };
      }

      if (this.currentEventData.type === helpers.eventTypes.groupBooking) {
        errorNotification(
          "group_booking_update_unavailable_in_calendar_view",
          {},
          false
        );
        info.revert();
        return;
      }

      this.fillEventWithDates(
        info.event.startStr,
        info.event.endStr,
        info.event.allDay
      );

      const eventActionName =
        this.currentEventData.type === helpers.eventTypes.unavailableBookingSlot
          ? "UpdateBlackoutDate"
          : "UpdateBooking";

      pushEvent(`calendarEventMove${eventActionName}`);

      const action =
        this.currentEventData.type === helpers.eventTypes.booking
          ? this.updateBooking
          : this.updateUnavailableBookingSpot;
      action(this.currentEventData).then(() => {
        this.reloadCalendar();
      });
    },
    getEvents: debounce((info, successCallback, failureCallback) => {
      getEvents(info, successCallback, failureCallback);
    }, 500),
    getResourceFreeSlots: debounce((info, successCallback, failureCallback) => {
      getResourceFreeSlots(info, successCallback, failureCallback);
    }, 500),
  },
};
</script>
