<template>
  <v-container>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <calendesk-warning-message style="max-width: 320px">
          {{ $trans("no_employee_selected_in_calendar") }}
        </calendesk-warning-message>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CalendeskWarningMessage from "@/lib/calendesk-js-library/components/CalendeskWarningMessage.vue";

export default {
  name: "EmployeeNotSelectedOverlay",
  components: { CalendeskWarningMessage },
};
</script>
