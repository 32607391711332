<template>
  <v-container
    v-if="!isSending"
    id="calendar"
    class="mb-16"
    fluid
    tag="section"
  >
    <v-row v-if="employeesExist">
      <v-col cols="12">
        <calendesk-full-calendar />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <no-data-info
          :button-text="$trans('add_employee')"
          :description="$trans('no_employees_info')"
          :redirect-to-route="{ name: 'add_employee' }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CalendeskFullCalendar from "@/views/dashboard/pages/Calendar/components/CalendeskFullCalendar";
import NoDataInfo from "@/components/common/NoDataInfo";
import statsActions from "@/calendesk/mixins/statsActions";

export default {
  name: "MainCalendar",
  components: { NoDataInfo, CalendeskFullCalendar },
  mixins: [statsActions],
  data() {
    return {
      statsMode: this.$helpers.statsMode.bookings,
    };
  },
};
</script>
